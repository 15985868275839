import Axios from '@/utils/axios';
import Api from '@/constant/api';

// 进入页面 获取配置信息
export const getPosConfig = async (mv) => {
  return Axios.jsonGet(`${Api.dp.pos.config}${mv}/`, {});
};

// 修改配置信息
export const submitPosConfig = async (params) => {
  return Axios.jsonPost(`${Api.dp.pos.config}${params.month_version}/`, params);
};

// 数据更新按钮
export const dataUpdatePos = async (params) => {
  return Axios.jsonPost(Api.dp.pos.update, params);
};

export const dataUpdateNielsen = async (params) => {
  return Axios.jsonPost(Api.dp.nielsen.update, params);
};

export const getNielsenConfig = async (mv) => {
  return Axios.jsonGet(`${Api.dp.nielsen.config}${mv}/`, {});
};

export const submitNielsenConfig = async (params) => {
  return Axios.jsonPost(`${Api.dp.nielsen.config}${params.month_version}/`, params);
};

export const cbecDataUpdate = async (params) => {
  return Axios.jsonPost(Api.dp.pos.update_data, params);
};
