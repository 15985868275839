import { makeAutoObservable } from 'mobx';
import * as userService from '@/services/user';

export class Application {
  menuCollapsed = false;
  loading = false;
  user = undefined;
  menuOpens = [];
  menuSelects = [];

  constructor() {
    makeAutoObservable(this);
  }

  updateMenuData(open, select) {
    if (open) {
      this.menuOpens = open;
    }
    if (select) {
      this.menuSelects = select;
    }
  }

  updateMenuCollapsed(val) {
    this.menuCollapsed = val;
  }

  updateLoading(val) {
    this.loading = val;
  }

  async getAsyncInfo() {
    const res = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({ code: 0, data: {} });
      }, 1000);
    });
    return res;
  }

  async getUserInfo() {
    const res = await userService.getUserInfo();
    if (res.code === 0) {
      this.user = res.data.user;
    }
    return res;
  }

  async logout() {
    const res = await userService.logout();
    if (res.code === 0) {
      this.user = undefined;
    }
    return res;
  }
}

export const APPLICATION = 'application';
