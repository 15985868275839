import { makeAutoObservable } from 'mobx';
import * as commonService from '@/services/common';

export class Common {
  months = [];
  common = {}

  constructor() {
    makeAutoObservable(this);
  }

  getPosProcess(params) {
    return commonService.getPosProcess(params);
  }

  getNielsenProcess(params) {
    return commonService.getNielsenProcess(params);
  }

  async getMonths(params) {
    const res = await commonService.getMonths(params);
    if (res.code === 0) {
      this.months = res.data.months;
    }
    return res;
  }

  fileUpload(params) {
    return commonService.fileUpload(params);
  }

  downloadStart(params) {
    return commonService.downloadStart(params);
  }

  downloadStatus(params) {
    return commonService.downloadStatus(params);
  }



}

export const COMMON = 'common';
