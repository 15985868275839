import { makeAutoObservable } from 'mobx';
import * as faService from '@/services/fa';

export class Fa {
  constructor() {
    makeAutoObservable(this);
  }

  // pos
  getPosSetting(params) {
    return faService.getPosSetting(params);
  }

  submitPos(params) {
    return faService.submitPos(params);
  }

  getPosDetail(params) {
    return faService.getPosDetail(params);
  }

  submitAdjustPos(params) {
    return faService.submitAdjustPos(params);
  }

  adjustPos(params) {
    return faService.adjustPos(params);
  }

  saveResult(params) {
    return faService.saveResult(params);
  }

  getLogParams(params) {
    return faService.getLogParams(params);
  }

  getLogs(params) {
    return faService.getLogs(params);
  }

  deleteLog(params) {
    return faService.deleteLog(params);
  }

  check(params) {
    return faService.check(params);
  }

  inputConfirm(params) {
    return faService.inputConfirm(params);
  }

  rollback(params) {
    return faService.rollback(params);
  }

  erase(params) {
    return faService.erase(params);
  }

  changeAlias(params) {
    return faService.changeAlias(params);
  }

  getSlopeSetting(params) {
    return faService.getSlopeSetting(params);
  }

  submitSlopeSetting(params) {
    return faService.submitSlopeSetting(params);
  }

  getAggLogList(params) {
    return faService.getAggLogList(params);
  }

  submitLogSave(params) {
    return faService.submitLogSave(params);
  }

  // nielsen
  getNielsenSetting(params) {
    return faService.getNielsenSetting(params);
  }

  submitNielsen(params) {
    return faService.submitNielsen(params);
  }

  getNielsenDetail(params) {
    return faService.getNielsenDetail(params);
  }
}

export const FA = 'fa';
