import Axios from '@/utils/axios';
import Api from '@/constant/api';

// 获取log options选项
export const getLogParams = async (params) => {
  return Axios.jsonGet(`${Api.log.log}${params.month_version}/`, {params});
};

// 获取log列表
export const getLogs = async (params) => {
  return Axios.jsonPost(`${Api.log.log}${params.month_version}/`, params);
};

// // 删除log
// export const deleteLog = async (params) => {
//   return Axios.jsonDel(`${Api.fr.result.logDelete}${params.id}/`, params);
// };

// // 修改别名
// export const changeAlias = async (params) => {
//   return Axios.jsonPost(`${Api.fr.result.changeAlias}${params.id}/`, params);
// };



