import { makeAutoObservable } from 'mobx';
import * as dpService from '@/services/dp';

export class Dp {
  sysHeader = [];
  sysBody = [];
  brandHeader = [];
  brandBody = [];

  constructor() {
    makeAutoObservable(this);
  }

  getPosConfig(params) {
    return dpService.getPosConfig(params);
  }

  getNielsenConfig(params) {
    return dpService.getNielsenConfig(params);
  }

  submitPosConfig(params) {
    return dpService.submitPosConfig(params);
  }

  dataUpdatePos(params) {
    return dpService.dataUpdatePos(params);
  }

  dataUpdateNielsen(params) {
    return dpService.dataUpdateNielsen(params);
  }

  submitNielsenConfig(params) {
    return dpService.submitNielsenConfig(params);
  }

  updateSysData(header, body) {
    if (header) {
      this.sysHeader = header;
    }
    if (body) {
      this.sysBody = body;
    }
  }

  updateBrandData(header, body) {
    if (header) {
      this.brandHeader = header;
    }
    if (body) {
      this.brandBody = body;
    }
  }

  cbecDataUpdate(params) {
    return dpService.cbecDataUpdate(params);
  }
}

export const DP = 'dp';
