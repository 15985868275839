const isProd = process.env.NODE_ENV === 'production';
// 是否测试环境
const isTestEnv = process.env.TESTING_PREFIX === 'offline';

const isPreEnv = process.env.TESTING_PREFIX === 'pre';
// 是否线上环境，用于区分测试环境
const isOnline = isProd && !isTestEnv && !isPreEnv;

const AXIOS_SERVER_HOST = 'smartplan.rfc-china.com';

// 浏览器host
const BROWSER_HOST = window.location.host;
// protocal
const PROTOCOL = window.location.protocol;
// 开发环境：后端ip
// const DEV_SERVER_HOST = 'ad-creative-boe.bytedance.net';
const TEST_SERVER_HOST = '212.64.76.24';
const PRE_SERVER_HOST = '212.64.76.24';
// const DEV_SERVER_HOST = '212.64.76.24';
const DEV_SERVER_HOST = 'nas.xiaoluoluo.cn';
// const DEV_SERVER_HOST = '192.168.3.12';
// const DEV_SERVER_HOST = '127.0.0.1';
// 开发环境：后端port
const DEV_SERVER_PORT = isPreEnv ? 80 : 10086;
// 开发环境 URL
const DEV_URL = `http://${isTestEnv ? TEST_SERVER_HOST : isPreEnv ? PRE_SERVER_HOST : DEV_SERVER_HOST}:${DEV_SERVER_PORT}`;

const onLineRemote = `${PROTOCOL}//${AXIOS_SERVER_HOST}`;

const config = {
  isProd,
  name: 'smart forecast',
  contextRoot: '',
  serviceHost: AXIOS_SERVER_HOST,
  servicePort: isOnline ? 443 : 80,
  servicePath: '',
  remoteHostUrl: isOnline ? onLineRemote : DEV_URL,
  isOnline,
  SERVER_REMOT_TIMEOUT: 60000,
  BROWSER_HOST,
  DEV_SERVER_HOST,
  DEV_SERVER_PORT,
  commonAxiosProps: {
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-csrftoken',
  },
};

export default config;
