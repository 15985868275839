import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store';
import Loading from './loading';

const Auth = observer((props) => {
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const { application, common } = useStores();
  useEffect(() => {
    if (!props.useAuth) {
      setAuth(true);
    } else {
      fetchUser();
      getMonths();
    }
  }, []);

  const fetchUser = async () => {
    if (application.user) {
      return setAuth(true);
    }
    const res = await application.getUserInfo();
    if (res.code !== 0) {
      navigate('/login');
    } else {
      setAuth(true);
    }
  };

  const getMonths = async () => {
    const res = await common.getMonths();
    console.log(res);
  }
  return <>{auth ? props.children : <Loading />}</>;
});
export default Auth;
