import { ConfigProvider } from 'antd';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routers';
import { StoresContext, stores } from './store';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import './App.less';
import './styles/global.module.scss';

const isEn = false;

const Page = () => {
  return useRoutes(routes);
};

function App() {
  return (
    <ConfigProvider locale={isEn ? enUS : zhCN}>
      <BrowserRouter>
        <StoresContext.Provider value={stores}>
          <Page />
        </StoresContext.Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
