import Axios from '@/utils/axios';
import Api from '@/constant/api';

export const getUserList = async (params) => {
  return Axios.jsonGet(Api.miniUser.user_list, { params });
};

export const modifyLevel = async (params) => {
  return Axios.jsonPost(Api.miniUser.modify_level, params);
};

export const distribute = async (params) => {
  return Axios.jsonPost(Api.distribution.distribute, params);
};

export const userLogin = async (body) => {
  return Axios.jsonPost(Api.user.login, body);
};

export const getUserInfo = async (params) => {
  return Axios.jsonGet(Api.user.info, { params });
};

export const changePassword = async (params) => {
  return Axios.jsonPost(Api.user.password, params);
};

export const logout = async (params) => {
  return Axios.jsonGet(Api.user.logout, { params });
};
