import Axios from '@/utils/axios';
import Api from '@/constant/api';

export const getOverview = async (params) => {
  return Axios.jsonGet(Api.home.overview, { params });
};

export const getTopSales = async (params) => {
  return Axios.jsonGet(Api.home.top_sales, { params });
};

export const getTopOrders = async (params) => {
  return Axios.jsonGet(Api.home.top_orders, { params });
};
