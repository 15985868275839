import { makeAutoObservable } from 'mobx';
import * as ciService from '@/services/ci';

export class Ci {

  constructor() {
    makeAutoObservable(this);
  }

  // pos
  getPosSetting(params) {
    return ciService.getPosSetting(params);
  }

  submitPos(params) {
    return ciService.submitPos(params);
  }

  getPosDetail(params) {
    return ciService.getPosDetail(params);
  }

  getNielsenSetting(params) {
    return ciService.getNielsenSetting(params);
  }

  submitNielsen(params) {
    return ciService.submitNielsen(params);
  }

  getNielsenDetail(params) {
    return ciService.getNielsenDetail(params);
  }
}

export const CI = 'ci';
