import Axios from '@/utils/axios';
import Api from '@/constant/api';

// pos
// 进入页面
export const getPosSetting = async (month_version) => {
  return Axios.jsonGet(`${Api.ci.pos.setting}${month_version}/`, {month_version});
};

// 查询
export const submitPos = async (params) => {
  return Axios.jsonPost(`${Api.ci.pos.setting}${params.month_version}/`, params);
};

// 获取详情
export const getPosDetail = async (conversion_id) => {
  return Axios.jsonGet(`${Api.ci.pos.detail}${conversion_id}/`, {conversion_id});
};


// nielsen
// 进入页面
export const getNielsenSetting = async (month_version) => {
  return Axios.jsonGet(`${Api.ci.nielsen.setting}${month_version}/`, {month_version});
};

// 修改
export const submitNielsen = async (params) => {
  return Axios.jsonPost(`${Api.ci.nielsen.setting}${params.month_version}/`, params);
};

// 获取详情
export const getNielsenDetail = async (assumption_id) => {
  return Axios.jsonGet(`${Api.ci.nielsen.detail}${assumption_id}/`, {assumption_id});
};
