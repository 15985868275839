import Axios from '@/utils/axios';
import Api from '@/constant/api';

export const getPosProcess = async (mv) => {
  return Axios.jsonGet(`${Api.common.pos_process}${mv}/`, {});
};

export const getNielsenProcess = async (mv) => {
  return Axios.jsonGet(`${Api.common.nielsen_process}${mv}/`, {});
};

export const getMonths = async (params) => {
  return Axios.jsonGet(`${Api.common.months}`, { params });
};

export const fileUpload = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.upload}`, params);
};

export const downloadStart = async (params) => {
  return Axios.jsonPost(`${Api.common.downloadTask}`, params);
};

export const downloadStatus = async (params) => {
  return Axios.jsonGet(`${Api.common.downloadTask}`, {params}, true);
};
