import React from 'react';
import { Layout, Spin } from 'antd';
import CustomerHeader from '@/components/Header';
import CustomerMenu from '@/components/Menu';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store';
import s from './index.module';

const { Header, Sider, Content } = Layout;

const Root = observer((props) => {
  const {
    application: { menuCollapsed, loading },
  } = useStores();
  return (
    <>
      <Spin tip="loading..." spinning={loading} wrapperClassName="loading-wraper">
        <Layout className={s.root}>
          <Header className={s.header}>
            <CustomerHeader />
          </Header>
          <Layout>
            <Sider className={s.sider} theme="light" width={260} collapsed={menuCollapsed} collapsedWidth={48}>
              <CustomerMenu />
            </Sider>
            <Content id="content-v1" className={s.content}>
              {props.children}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </>
  );
});

export default Root;
