import React, { useState } from 'react';
import { Badge, Avatar, Dropdown, Menu, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useStores } from '@/store';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import logo from './logo.png';
import s from './index.module';

const Header = observer((props) => {
  // const [name, setName] = useState('小红花');
  const { application } = useStores();
  const navigate = useNavigate();
  const { username = '', avatar } = application.user || {};
  //   const name = '小红花';
  console.log('application', application.user);
  const logout = async () => {
    const res = await application.logout();
    if (res.code !== 0) {
      message.error(res.msg || 'error');
    }
    navigate('/login');
    message.success('已退出');
  };
  const items = [
    {
      label: (
        <div onClick={logout}>
          <LogoutOutlined /> 退出
        </div>
      ),
      key: '0',
    },
  ];
  return (
    <div className={s.header}>
      <div className={s.logo}>
        <img className={classnames(s.logoIcon)} src={logo} alt="" />
        <h1 className={s.title}>Smart Cube</h1>
      </div>

      <Badge count={0} size="small">
        <Avatar style={{ backgroundColor: 'gray' }}>{username.slice(0, 1).toUpperCase()}</Avatar>
      </Badge>
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomRight"
      >
        <span className={s.username}>{username}</span>
      </Dropdown>
    </div>
  );
});

export default Header;
