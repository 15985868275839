import { makeAutoObservable } from 'mobx';
import * as loService from '@/services/lo';

export class Lo {

  constructor() {
    makeAutoObservable(this);
  }

  getLogParams(params) {
    return loService.getLogParams(params);
  }

  getLogs(params) {
    return loService.getLogs(params);
  }

}

export const LO = 'lo';
