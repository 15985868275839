import axios from 'axios';
import config from '@/constant/config';
import qs from 'qs';
import { stores } from '@/store';
import { message, notification } from 'antd';

const req = axios.create({
  ...config.commonAxiosProps,
  baseURL: `${config.contextRoot}${config.servicePath}`,
  timeout: 600000 * 5,
});

req.interceptors.request.use(
  (config) => {
    const { application } = stores;
    if (!config.disableLoading) {
      application.updateLoading(true);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

req.interceptors.response.use(
  (response) => {
    const { application } = stores;
    application.updateLoading(false);
    if (response.status !== 200) {
      message.error(response.statusText);
    } else {
      if (response.data.code !== 0) {
        if (response.data.code === 30021) {
          notification.error({
            message: 'Stage percent not 100%',
            description: response.data.msg,
            duration: 0,
          });
        } else if (![1103].includes(response.data.code)) {
          message.error(response.data.msg, 5);
        }
        if (response.data.code === 10001) {
          window.location.href = '/login';
        }
      }
    }
    return Promise.resolve(response);
  },
  (error) => {
    const { application } = stores;
    application.updateLoading(false);
    message.error('服务异常, 请稍后再试');
    return Promise.reject(error);
  }
);

export default class Axios {
  static cfetch(url, pOptions, disableLoading) {
    let cOptions = pOptions;
    if (!cOptions) {
      cOptions = {};
    }
    if (!cOptions.withCredentials) {
      cOptions.withCredentials = true;
    }
    if (disableLoading) {
      cOptions.disableLoading = true;
    }
    cOptions.url = url;
    return req(cOptions).then((res) => res.data);
  }

  static formPost(url, queryString, options) {
    return Axios.cfetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: queryString,
      ...options,
    });
  }

  static filePost(url, queryString, options) {
    return Axios.cfetch(url, {
      method: 'post',
      headers: {},
      data: queryString,
      ...options,
    });
  }

  static jsonGet(url, options = {}, disableLoading) {
    options.method = 'get';
    const { params } = options;
    if (params && Object.keys(params).length) {
      url = `${url}?${qs.stringify(params, { encode: false })}`;
    }
    delete options.params;
    return Axios.cfetch(url, options, disableLoading);
  }

  static jsonPost(url, request, options) {
    return Axios.cfetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(request),
      ...options,
    });
  }

  static jsonDel(url, request, options) {
    return Axios.cfetch(url, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(request),
      ...options,
    });
  }
}
