import { makeAutoObservable } from 'mobx';
import * as homeService from '@/services/home';

export class Home {

  home = {}

  constructor() {
    makeAutoObservable(this);
  }

  getOverview(params) {
    return homeService.getOverview(params);
  }

  getTopSales(params) {
    return homeService.getTopSales(params);
  }

  getTopOrders(params) {
    return homeService.getTopOrders(params);
  }
}

export const HOME = 'home';
