import { makeAutoObservable } from 'mobx';
import * as exRfcService from '@/services/exRfc';

export class ExRfc {
  constructor() {
    makeAutoObservable(this);
  }

  // 二期 新增
  getForecastBySkuList(params) {
    return exRfcService.getForecastBySkuList(params);
  }

  getForecasttBySkuSetting(params) {
    return exRfcService.getForecasttBySkuSetting(params);
  }

  saveForecasttBySku(params) {
    return exRfcService.saveForecasttBySku(params);
  }

  focExportCommit(params) {
    return exRfcService.focExportCommit(params);
  }

  exportStatus(params) {
    return exRfcService.exportStatus(params);
  }

  focDownload(params) {
    return exRfcService.focDownload(params);
  }

  focUploadConfirm(params) {
    return exRfcService.focUploadConfirm(params);
  }

  focUploadConfirmStatus(params) {
    return exRfcService.focUploadConfirmStatus(params);
  }

  changeDio(params) {
    return exRfcService.changeDio(params);
  }

  markFinal(params) {
    return exRfcService.markFinal(params);
  }

  saveAs(params) {
    return exRfcService.saveAs(params);
  }
  changeVersionDio(params) {
    return exRfcService.changeVersionDio(params);
  }
  delVersion(params) {
    return exRfcService.delVersion(params);
  }
  getVersionInfo(params) {
    return exRfcService.getVersionInfo(params);
  }
  confirmFinalVersion(params) {
    return exRfcService.confirmFinalVersion(params);
  }
}

export const EXRFC = 'exRfc';
