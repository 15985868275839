import { makeAutoObservable } from 'mobx';
import * as userService from '@/services/user';

export class User {
  user_list = [];
  pagination = {};
  constructor() {
    makeAutoObservable(this);
  }

  async getUserList(params) {
    const res = await userService.getUserList(params);
    this.user_list = res.data.list;
    this.pagination = res.data.pagination;
    return res;
  }

  modifyLevel(body) {
    return userService.modifyLevel(body);
  }

  distribute(body) {
    return userService.distribute(body);
  }

  userLogin(params) {
    return userService.userLogin(params);
  }
  changePassword(params) {
    return userService.changePassword(params);
  }
}

export const USER = 'user';
