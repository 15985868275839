import { makeAutoObservable } from 'mobx';
import * as frService from '@/services/fr';

export class Fr {
  constructor() {
    makeAutoObservable(this);
  }

  // result -left
  getResultSetting(params) {
    return frService.getResultSetting(params);
  }

  submitResult(params) {
    return frService.submitResult(params);
  }

  getResultDetail(params) {
    return frService.getResultDetail(params);
  }

  // result -right
  getRightSetting(params) {
    return frService.getRightSetting(params);
  }
  submitRightResult(params) {
    return frService.submitRightResult(params);
  }

  getRightDetail(params) {
    return frService.getRightDetail(params);
  }

  modifyQuarterly(params) {
    return frService.modifyQuarterly(params);
  }

  modifyMonthly(params) {
    return frService.modifyMonthly(params);
  }

  modifyMonthlyTemp(params) {
    return frService.modifyMonthlyTemp(params);
  }

  saveResult(params) {
    return frService.saveResult(params);
  }

  getLogParams(params) {
    return frService.getLogParams(params);
  }

  getLogs(params) {
    return frService.getLogs(params);
  }

  deleteLog(params) {
    return frService.deleteLog(params);
  }

  check(params) {
    return frService.check(params);
  }

  inputConfirm(params) {
    return frService.inputConfirm(params);
  }

  rollback(params) {
    return frService.rollback(params);
  }

  changeAlias(params) {
    return frService.changeAlias(params);
  }

  erase(params) {
    return frService.erase(params);
  }

  eraseAdjust(params) {
    return frService.eraseAdjust(params);
  }

  getOutputSetting(params) {
    return frService.getOutputSetting(params);
  }

  submitOutputSetting(params) {
    return frService.submitOutputSetting(params);
  }

  outputDownload(params) {
    return frService.outputDownload(params);
  }

  getSlopeSetting(params) {
    return frService.getSlopeSetting(params);
  }

  submitSlopeSetting(params) {
    return frService.submitSlopeSetting(params);
  }

  getSlopeDetail(params) {
    return frService.getSlopeDetail(params);
  }

  submitLogSave(params) {
    return frService.submitLogSave(params);
  }

  // 二期 新增
  getResultBySkuList(params) {
    return frService.getResultBySkuList(params);
  }

  getResultBySkuSetting(params) {
    return frService.getResultBySkuSetting(params);
  }

  getResultBySkuCalc(params) {
    return frService.getResultBySkuCalc(params);
  }

  getResultBySkuCalcStatus(params) {
    return frService.getResultBySkuCalcStatus(params);
  }

  changePercent(params) {
    return frService.changePercent(params);
  }

  confirmChange(params) {
    return frService.confirmChange(params);
  }

  resetChange(params) {
    return frService.resetChange(params);
  }

  getCbecList(params) {
    return frService.getCbecList(params);
  }

  getCbecSetting(params) {
    return frService.getCbecSetting(params);
  }

  cbecUpdate(params) {
    return frService.cbecUpdate(params);
  }
  adjustVal(params) {
    return frService.adjustVal(params);
  }

  markDataSplit(params) {
    return frService.markDataSplit(params);
  }

  changeAllPercent(params) {
    return frService.changeAllPercent(params);
  }

  changeTableData(params) {
    return frService.changeTableData(params);
  }

  cbecUpdateV2(params) {
    return frService.cbecUpdateV2(params);
  }
}

export const FR = 'fr';
